import React, { useEffect, useState, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import { logEvent, getAnalytics } from 'firebase/analytics';
import {
  collection,
  addDoc,
  getFirestore,
  Timestamp
} from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Loader } from '@googlemaps/js-api-loader';
import { CssBaseline, LinearProgress, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/syncopate';
import '@fontsource/oxygen';

import './modules/firebase';
import App from './screens/App';
import ScrollToTop from './components/ScrollToTop';
import SearchResults from './screens/SearchResults';
import Profile from './screens/Profile';
import Members from './screens/Members';
import Unknown from './screens/404';
import Mission from './screens/Mission';
import Privacy from './screens/Privacy';
import Store from './screens/Store';
import Checkout from './screens/Checkout';
import FAQ from './screens/FAQ';
import Contact from './screens/Contact';
import AccessControl from './components/AccessControl';
import TermsConditions from './screens/TermsConditions';
import PostCheckout from './screens/PostCheckout';
import ThankYou from './screens/ThankYou';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Promos from './screens/Promos';
import VisualMedia from './screens/VisualMedia';
import Dashboard from './components/Dashboard';
import MemberHome from './components/MemberHome';
import RefreshOnboarding from './components/RefreshOnboarding';
import { loadFeelGood } from './modules/utils';

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    const logVisitor = async () => {
      logEvent(getAnalytics(), 'page_view', {
        page_location: location.pathname
      });
      const res = await fetch('https://geo.sunbowl.ca/json');
      const { ip, country_name } = await res.json();
      console.log('Visitor', ip, country_name, location.pathname);
      addDoc(collection(getFirestore(), 'logs'), {
        page: location.pathname,
        timestamp: Timestamp.now(),
        ip,
        countryName: country_name,
        description: `${new Date()}: ${ip} (${country_name}) just viewed ${
          location.pathname
        }`,
        type: 'analytics'
      });
    };

    if (process.env.NODE_ENV === 'production') {
      logVisitor();
    }
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />} />

        <Route path="/results" element={<SearchResults />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/members" element={<Members />} />
        <Route path="/store" element={<Store />} />
        <Route path="/promos" element={<Promos />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/visual-media" element={<VisualMedia />} />
        <Route path="/payment/:state">
          <Route path="" element={<PostCheckout />} />
          <Route path=":sessionId" element={<PostCheckout />} />
        </Route>
        <Route path="/profiles/:profileId" element={<Profile />} />
        <Route path="/checkout/:purchaseType/:purchaseId">
          <Route path=":variantId" element={<Checkout />} />
          <Route path="" element={<Checkout />} />
        </Route>
        <Route path="/member" element={<MemberHome />} />
        <Route
          path="/member/refresh-onboarding"
          element={<RefreshOnboarding />}
        />
        <Route path="/member/login" element={<Login />} />
        <Route path="/member/signup">
          <Route path=":referralMemberId" element={<SignUp />} />
          <Route path="" element={<SignUp />} />
        </Route>
        <Route path="/member/dashboard" element={<Dashboard />} />
        <Route path="*" element={<Unknown />} />
      </Routes>
    </>
  );
};

const SynergyDivingApp = () => {
  const [loaded, setLoaded] = useState(false);
  const [accessGranted, setAccessGranted] = useState(false);

  useEffect(() => {
    // load the Google setup, the feel good phrases, and the logo
    const setupApp = async () => {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        version: 'weekly',
        libraries: ['places', 'geometry']
      });
      loader.load().then(async () => {
        // await loadLogo();
        await loadFeelGood();
        setLoaded(true);
      });
    };
    setupApp();
  }, []);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  /*
Sent screenshots on telegram but I was using 
#001F60 - dark blue
#027EBC - turquoisey blue
#01BAD6 - light blue
#D9F4FF - almost white blue


#43accb

 light blue (ocean) - 1abddd
 fish (blue/purple) - 123ba3
 yellow/brown (rocks) - ab866a
  turtle skin (light) - a4b1b5
  turtle shell (purple) - 6d7a9d
  */

  // favs: 397898, 568cb4

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            light: '#1abddd',
            main: prefersDarkMode ? '#1abddd' : '#568cb4',
            contrastText: prefersDarkMode ? '#1d211f' : '#fff'
          },
          secondary: {
            main: '#b9c477'
          },
          background: {
            default: prefersDarkMode ? '#1d211f' : '#fff',
            paper: prefersDarkMode ? '#1d211f' : '#fff'
          }
        },
        typography: {
          fontFamily: 'Oxygen',
          caption: {
            fontFamily: 'Syncopate'
          },
          button: {
            fontFamily: 'Syncopate'
          },
          h2: {
            fontFamily: 'Syncopate'
          },
          h3: {
            fontFamily: 'Syncopate'
          },
          h4: {
            fontFamily: 'Syncopate'
          },
          h5: {
            fontFamily: 'Syncopate'
          },
          h6: {
            fontFamily: 'Syncopate'
          }
        }
      }),
    [prefersDarkMode]
  );

  let content;
  if (
    window.location.href.startsWith(process.env.REACT_APP_DEVELOPMENT_URL) &&
    !accessGranted
  ) {
    content = <AccessControl setAccessGranted={setAccessGranted} />;
  } else if (!loaded) {
    content = <LinearProgress />;
  } else {
    content = <AppRoutes />;
  }

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Elements stripe={stripePromise}>
          <Router>{content}</Router>
        </Elements>
      </ThemeProvider>
    </React.StrictMode>
  );
};

createRoot(document.getElementById('root')).render(<SynergyDivingApp />);
